import { useState, useEffect } from 'react'
import { getHasElectronContext, probeLANStorageConnections, connectProjectToLANStorage, disconnectFromLANStorage, getProjectActiveConnectionUrl, addStorageProject, getStorageProjects, removeStorageProject } from '../../models3/SlttAppStorage'
import { t } from 'ttag'
import { observer } from 'mobx-react'
import _debug from 'debug'
import { userError, displaySuccess } from '../utils/Errors'
import './LocalTeamStorageButton.css'
import ReactTooltip from 'react-tooltip'
import { AppRoot } from '../../models3/AppRoot'
import { throttle } from 'underscore'

const log = _debug('sltt:LocalTeamStorageButton')

const DEFAULT_LAN_CONNECTION_URL = localStorage.defaultLanConnectionUrl ||
    'file://192.168.8.1/sltt-local-team-storage/sltt-app/lan'

type LocalTeamStorageButtonProps = {
    appRoot: AppRoot
    initialized: boolean
    project: string
    iAmAdmin: boolean
    userEmail: string
    projectAdmins: string[]
}

export const LocalTeamStorageButton = observer(({ appRoot, initialized, project, iAmAdmin, userEmail, projectAdmins }: LocalTeamStorageButtonProps) => {
    const [isEnabledForProject, setIsEnabledForProject] = useState('')
    const [isAvailable, setIsAvailable] = useState(false)
    const [isConnected, setIsConnected] = useState(false)
    const [tooltip, setTooltip] = useState('')
    const [tooltipVisible, setTooltipVisible] = useState(true) // State variable for tooltip visibility
    const [connectionUrl, setConnectionUrl] = useState(getProjectActiveConnectionUrl(project) || DEFAULT_LAN_CONNECTION_URL)
    const [currentProject, setCurrentProject] = useState(project)

    const makeMesssageJsx = (message: string) => <div>{message}&nbsp;&nbsp;<i className={`fa fa-solid fa-network-wired fa-network-wired`} /></div>

    const fetchConnections = async () => {
        log('state', {
            isEnabledForProject,
            isAvailable,
            isConnected,
            tooltip,
            connectionUrl,
            project,
            iAmAdmin,
            projectAdmins
        })
        setCurrentProject(project)
        const projectConnectionUrl = getProjectActiveConnectionUrl(project)
        const potentialConnectionUrl = projectConnectionUrl || DEFAULT_LAN_CONNECTION_URL
        if (potentialConnectionUrl !== connectionUrl) {
            setConnectionUrl(potentialConnectionUrl)
        }
        const connectionStatus = await probeLANStorageConnections([potentialConnectionUrl])
        const status = connectionStatus.find(c => c.url === potentialConnectionUrl)
        if (!status) {
            throw new Error(`Connection not found for ${potentialConnectionUrl}`)
        }
        const isUrlAvailable = status.accessible
        const isNowConnected = !!projectConnectionUrl
        setIsAvailable(isUrlAvailable)
        if (!isUrlAvailable) {
            if ((project === currentProject) && isAvailable && (isConnected || isNowConnected)) {
                // if was available, then we lost connection. make a toast
                userError(makeMesssageJsx(t`Local team storage connection lost.`))
                disconnectFromLANStorage(project)
            } else if (isAvailable) {
                // if was available, then we lost connection. make a toast
                userError(makeMesssageJsx(t`Local team storage no longer available.`))
            } 
            setIsConnected(false)
            setIsEnabledForProject('')
            return
        }

        const MSG_LOCAL_TEAM_STORAGE_AVAILABLE = t`Local team storage available.`
        const projects: string[] = await getStorageProjects({ url: potentialConnectionUrl }, 'LocalTeamStorageButton')
        const hasCurrentProject = projects.includes(project)
        setIsEnabledForProject(hasCurrentProject ? project : '')
        if (!hasCurrentProject) {
            setIsConnected(false)
            if (isEnabledForProject === project) {
                userError(makeMesssageJsx(t`An admin disabled local team storage for this project.`))
                disconnectFromLANStorage(project)
            }
            else if (!isAvailable || project !== currentProject) {
                displaySuccess(makeMesssageJsx(MSG_LOCAL_TEAM_STORAGE_AVAILABLE))
            }
            return
        } else {
            setIsConnected(isNowConnected)
            if (!isEnabledForProject && !isNowConnected) {
                displaySuccess(makeMesssageJsx(t`An admin enabled local team storage for this project.`))
            }
            if (isNowConnected) {
                if (!isConnected) {
                    displaySuccess(makeMesssageJsx(t`Now using local team storage for this project.`))
                }
            } else if (!isAvailable) {
                displaySuccess(makeMesssageJsx(MSG_LOCAL_TEAM_STORAGE_AVAILABLE))
            }
            if (isNowConnected) {
                appRoot?.rt?.project.db.doSync()
                // TODO: also sync for other initialized projects (in background)?
                // that's what the websocket DBChangeNotifier does.
                // but DBChangeNotifier is only setup when online/internet is available
                // and that will update every 2.5 minutes offline
            }
        }
    }

    const throttledFetchConnections = throttle(fetchConnections, 10000, { leading: true })

    useEffect(() => {
        // update Tooltip state
        if (!isAvailable) {
            return
        }

        const toolTipUseLocalStorage = t`Click to use local team storage`
        if (!isEnabledForProject) {
            if (iAmAdmin) {
                setTooltip(toolTipUseLocalStorage + '<br>' + t`(And to enable local team storage for team)`)
            } else {
                setTooltip(t`An admin must first click to enable local team storage for team:` + ' ' + projectAdmins.join(', '))
            }
            return
        }

        if (isConnected) {
            setTooltip(t`Click to stop using local team storage`)
            return
        }

        if (iAmAdmin) {
            // is iAmAdmin and has current project
            setTooltip(
                toolTipUseLocalStorage +
                '<br>' + t`Or hold <SHIFT> key then click to disable project local team storage for team`
            )
        } else {
            // non-admin
            setTooltip(toolTipUseLocalStorage)
        }

    }, [isEnabledForProject, isAvailable, isConnected, tooltip, connectionUrl, project, iAmAdmin, projectAdmins])

    useEffect(() => {
        // Temporarily hide and show the tooltip to force re-render
        setTooltipVisible(false)
        const timeoutId = setTimeout(() => setTooltipVisible(true), 1)
        return () => clearTimeout(timeoutId)
    }, [tooltip])


    useEffect(() => {
        if (!getHasElectronContext() || !initialized) {
            return
        }
        throttledFetchConnections() // Initial fetch
        const intervalId = setInterval(throttledFetchConnections, 10000) // Fetch every 10 seconds
        return () => {
            // Cleanup interval on component unmount
            clearInterval(intervalId)
            throttledFetchConnections.cancel()
        }
    }, [initialized, appRoot, project, iAmAdmin, userEmail, projectAdmins,
        throttledFetchConnections, currentProject, connectionUrl,
        isEnabledForProject, isAvailable, isConnected, tooltip, tooltipVisible,
    ])

    if (!getHasElectronContext()) {
        return null
    }

    if (!isAvailable) {
        return null
    }

    const onClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!isEnabledForProject) {
            if (iAmAdmin) {
                await addStorageProject({ project, adminEmail: userEmail, url: connectionUrl }, 'LocalTeamStorageButton');
                // connect
                try {
                    await connectProjectToLANStorage({ project, url: connectionUrl })
                    setIsEnabledForProject(project)
                    setIsConnected(true)
                    displaySuccess(makeMesssageJsx(t`Local team storage enabled for team`))
                    appRoot.reinitializeProjects() /* (async) with VideoCache.initialize() */
                } catch (error) {
                    userError(makeMesssageJsx(t`Error enabling local team storage for team`))
                }
            }
            return
        }
        if (isConnected) {
            await disconnectFromLANStorage(project)
            setIsConnected(false)
            return
        }
        // disconnected and enabled for project
        if (iAmAdmin && event.shiftKey) {
            await removeStorageProject({ project, adminEmail: userEmail, url: connectionUrl }, 'LocalTeamStorageButton')
            displaySuccess(makeMesssageJsx(t`Local team storage disabled for team`))
            setIsConnected(false)
            setIsEnabledForProject('')
            return
        }
        try {
            // connect
            await connectProjectToLANStorage({ project, url: connectionUrl })
            setIsConnected(true)
            appRoot.reinitializeProjects() /* (async) with VideoCache.initialize() */
        } catch (error) {
            userError(makeMesssageJsx(t`Error using local team storage for team`))
        }
    }

    const ttId = 'local-team-storage-icon-id'

    return (
        <div>
            <span className={`local-team-storage-icon local-team-storage-icon-${(iAmAdmin || isEnabledForProject) ? 'enabled' : 'disabled'}`}
                onClick={onClick}
                data-for={ttId}
                data-tip={tooltip}
                data-multiline={true}
                data-place="right"
            >
                <i className={`fa fa-solid fa-network-wired fa-network-wired`} />
                {!isConnected && <span className="local-team-storage-icon-disconnected">
                    <i className={`fa fa-solid fa-slash fa-rotate-90`} />
                </span>}
            </span>
            {tooltipVisible && (
                <ReactTooltip
                    id={ttId}
                    backgroundColor="gray"
                    effect="solid"
                    /* NOTE: `multiline` requires that we don't embed tooltip as ReactTooltip child */
                />
            )}
        </div>
    )
})